*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.middle_bg{
  background-image: url('./assets/img/roadmapBack.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.cursor-pointer {
  cursor: pointer;
}

.cs-mint_avatar img {
  visibility: inherit !important;
}

.mr-5{
  margin-right: 5px;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background: black;
  color: #666666;
  cursor: not-allowed;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  border-bottom: solid 1px var(--primary-color);
  opacity: 0.9;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--primary-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

.justify-around {
  justify-content: space-around;
}
.justify-between {
  justify-content: space-between;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-center {
  justify-content: center;
}

.bg-blue {
  background-color: #321fdb;
}

.minh-300 {
  min-height: 300px;
}
.maxh-300 {
  max-height: 300px;
}

.minw-1000 {
  min-height: 1000px;
}
.maxw-1000 {
  max-height: 1000px;
}


/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

.form input[type='submit'],
button {
  font: inherit;
}

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}
.form .social-input i.fa-facebook {
  color: #3b5998;
}
.form .social-input i.fa-instagram {
  color: #3f729b;
}
.form .social-input i.fa-youtube {
  color: #c4302b;
}
.form .social-input i.fa-linkedin {
  color: #0077b5;
}

.eth-btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.eth-btn-login {
  background-color: #321fdb;
  color: white;
  width: 150px;
  height: 50px;
  font-size: 20px;
}

.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
}

.abs-center{
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  margin: auto;
  max-height: 300px;
  max-width: 900px;
}

.mr-10 {
  margin-right: 10px;
}
.m-auto {
  margin: auto;
}
.advertise_part{
  height: 300px;
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.font-bold {
  font-weight: bold;
}
/* Mobile Styles */
@media (max-width: 700px) {
  /* Navbar */
  .navbar {
    display: block !important;
    text-align: center;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  .navbar h1 {
    margin-bottom: 1rem;
  }

  .navbar .welcome {
    display: none;
  }

  .advertise_part{
    height: 660px;
  }

  .roadmap_title{
    margin-left: 0px !important;
  }

  .roadmap_one_title{
    margin-left: 0px !important;
  }
  .roadmap_second_title{
    margin-left: 0px !important;
  }
  .roadmap_third_title{
    margin-left: 0px !important;
  }
  .middle_bg{
    font-size: 22px !important;
  }
  .first-title-border-left{
    height: 230px !important;
  }

  .jamOne {
    margin-top: 10px !important;
  }

  .jamThree{
    margin-top: 50px !important;
  }

  .jamSeven {
    margin-top: 50px !important;
  }
}

@media (min-width: 768px) {
  .col-md-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
  }
}